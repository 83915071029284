import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransformedVccCardType, VccCardType } from "../../types/common";
import { VccData } from "../../types/paymentTypes";

type VccState = {
  vccData: TransformedVccCardType | null;
  vccPaymentData: any | null;
};

export const RESET_STATE = "RESET_STATE";

const initialState: VccState = {
  vccData: null,
  vccPaymentData: null,
};

const vccSlice = createSlice({
  name: "VIRTUAL_CARD",
  initialState,
  reducers: {
    setVccData: (state, action: PayloadAction<VccCardType>) => {
      state.vccData = {
        target_account_id: action.payload.target_account_id,
        target_account_type: "VIRTUAL_CARD",
        target_account_title: "Virtual card",
        target_account_identifier: action.payload.card_number,
        balance: action.payload.balance,
      };
    },
    setVccPaymentData: (state, action: PayloadAction<any>) => {
      state.vccPaymentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const { setVccData, setVccPaymentData } = vccSlice.actions;

export default vccSlice.reducer;
