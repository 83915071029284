import React, { ReactElement } from "react";
import BrowseGalleryOutlinedIcon from "@mui/icons-material/BrowseGalleryOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterSharpIcon from "@mui/icons-material/WatchLaterSharp";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Color, styled } from "@mui/material";

export const TOKEN_STORAGE_KEY = "accessToken";

export const getLastFourDigits = (value: string) => {
  if (value.length > 4) {
    return value.substr(value.length - 4);
  }
  return value;
};

export const currencySymbols: Record<string, React.ReactNode> = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
};

export const displayStateIcon: Record<string, ReactElement<Color>> = {
  approved: <CheckCircleIcon color="success" />,
  pending: <BrowseGalleryOutlinedIcon color="info" />,
  incomplete: <WatchLaterSharpIcon color="info" />,
  declined: <CancelIcon color="error" />,
};

export const WhiteSecurityIcon = styled(HttpsOutlinedIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: "0.5rem",
}));

export const listItemStyle = {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: 1,
  borderColor: "divider",
  pl: 0,
};

export const getStipendStateDisplay = (state: string): string => {
  if (state === "Approved") {
    return "Claimed";
  } else if (state === "Incomplete") {
    return "Claim payment";
  } else {
    // Handle other states if needed
    return "";
  }
};

export const succesStatus = [200, 201, 204];

export const hiddenFooterPages = ["termsAndConditions", "support"];
export const backButtonHiddenPages = ["update-later"];
export const extraRegexPages = [
  "confirmPayment",
  "payment",
  "paymentMethods",
  "bank_account",
  "debit_card",
  "virtual_card",
  "check",
  "VIRTUAL_CARD",
  "vccCreate",
  "vccReview",
  "vccPreview",
  "vccCard",
  "vccSetup",
  "cheque",
  "stipend",
  "termsAndConditions",
];

export const clearTokenAndRedirect = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  window.location.href = "/";
};

export const sideMenuPages = ["payments", "support", "vcc-card"];

export const hideHeaderPages: string[] = [];
