import React from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  Container,
  Box,
} from "@mui/material";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { currencySymbols } from "../../common/constants";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../common/helpers";
import { selectCurrencySymbol } from "../../redux/slices/paymentMethodsSlice";
const VccCard: React.FC = () => {
  const vcc = useSelector<RootState, any | null>((state) => state.vcc.vccData);

  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: 0,
        mt: 2,
        marginLeft: "8px",
        marginRight: "8px",
        borderRadius: 1,
        border: "1px solid #0000001F",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        alignItems="center"
      >
        <ListItem dense disableGutters sx={{ padding: 0 }}>
          <CreditCardRoundedIcon
            data-test-id="vcc-icon"
            sx={{ fontSize: 30 }}
            style={{ color: "gray" }}
          />
          <ListItemText
            sx={{ cursor: "default", textAlign: "left", ml: 4 }}
            data-test-id="vcc-balance"
            primary={
              <Typography variant="subtitle2" fontWeight="600">
                {`${currencySymbol} `}
                {vcc ? formatNumber(vcc.balance) : "0.00"}
              </Typography>
            }
            secondary={
              <Typography
                data-test-id="vcc-balance-title"
                variant="body2"
                color="text.secondary"
              >
                {t("vcc_availableOnCard")}
              </Typography>
            }
          />
        </ListItem>
      </Box>
    </Box>
  );
};

export default VccCard;
