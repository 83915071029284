import { AxiosRequestConfig } from "axios";
import { api } from "./api";
import { GET_SNIPPET_HISTORY_URL, WAITING_STIPENDS_URL } from "./config";

export const getWaitingStipends = async (): Promise<any> => {
  const response = await api.get(WAITING_STIPENDS_URL);
  return response.data;
};

export const getStipendsHistory = async (
  pageNum: number,
  pageSize: number
): Promise<any> => {
  const endpoint = `${GET_SNIPPET_HISTORY_URL}/?page_number=${pageNum}&page_size=${pageSize}`;
  const response = await api.get(endpoint);
  return response.data;
};

export const paymentsService = (): AxiosRequestConfig => {
  return {
    url: "/v1/payments",
    method: "GET",
  };
};
