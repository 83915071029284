import { ReceiptLong } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import StarIcon from "@mui/icons-material/Star";
import {
  Alert,
  AlertTitle,
  Card,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { convertDateToFormattedString, formatNumber } from "../common/helpers";
import BankPaymentProcessingDialog from "../components/Payment/BankPaymentProcessingDialog";
import LatestPayment from "../components/Payment/LatestPayment";
import StipendCard from "../components/Stipend/StipendCard";
import {
  getStatusText,
  statusSeverity,
} from "../components/Stipend/StipendCommon";
import { selectCurrencySymbol } from "../redux/slices/paymentMethodsSlice";
import { RootState } from "../redux/store";
import { PaymentMethodsResponse, PaymentType } from "../types/paymentTypes";
import { Payment, PaymentStatus } from "../types/stipendTypes";

const PaymentDetails = () => {
  const { t } = useTranslation();
  const { transaction_id } = useParams<{ transaction_id: string }>();
  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  const allStipends = useSelector<RootState, Payment[]>(
    (state) => state.stipend.data
  );

  const paymentMethods: PaymentMethodsResponse | null = useSelector(
    (state: RootState) => state.paymentMethods.data
  );

  const stipend = allStipends.find(
    (payment) => payment.transaction_id === transaction_id
  );

  const stipendAmount = formatNumber(stipend?.amount ?? 0);
  const actionColor = useTheme().palette.action.active;

  const showChangeButton =
    stipend?.status === PaymentStatus.MISSING_PAYMENT_DETAILS ||
    stipend?.status === PaymentStatus.INVALID_PAYMENT_ACCOUNT;
  const failedPayment =
    showChangeButton && paymentMethods?.latest_payment_account !== null;

  const row = (title: string, description: string, icon?: React.ReactNode) => {
    return (
      <Alert
        data-test-id="row"
        variant="outlined"
        icon={icon}
        sx={{ width: "100%", border: "0px" }}
      >
        <AlertTitle>
          <Typography
            data-test-id={title}
            variant="subtitle2"
            color="text.secondary"
          >
            {title}
          </Typography>
        </AlertTitle>
        <Typography
          data-test-id={title + "-value"}
          variant="body2"
          color="text.primary"
          style={{ overflowWrap: "anywhere" }}
        >
          {description}
        </Typography>
      </Alert>
    );
  };

  // Modal state and handlers
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container direction="column">
      {failedPayment && (
        <StipendCard
          icon={<ErrorOutlineIcon />}
          title={t("failedPaymentWarning_title")}
          description={t("failedPaymentWarning_description")}
          color="white"
          backgroundColor="#D32F2F"
          linkTo="/payment-methods?failedPayment=true"
        />
      )}
      <Card variant="outlined">
        <List sx={{ paddingBottom: "0px" }}>
          <ListItem sx={{ padding: "0px" }}>
            {row(
              t("payment_rewardName"),
              stipend?.payment_reason ?? "Not available",
              <StarIcon color="action" />
            )}
          </ListItem>
          <Divider component="li" />

          <ListItem sx={{ padding: "0px" }}>
            {row(
              t("payment_amount"),
              `${currencySymbol} ${stipendAmount}`,
              <MonetizationOnIcon color="action" />
            )}
          </ListItem>
          <Divider component="li" />

          {stipend?.payment_account && (
            <>
              <ListItem sx={{ padding: "0px" }}>
                <LatestPayment
                  paymentAccount={stipend.payment_account}
                  failedPayment={failedPayment}
                  showChangeButton={showChangeButton}
                  useTableStyle={true}
                />
              </ListItem>
              <Divider component="li" />
            </>
          )}

          <ListItem sx={{ padding: "0px" }}>
            {row(
              t("payment_transactionId"),
              stipend?.transaction_id ?? "-",
              <ReceiptLong color="action" />
            )}
          </ListItem>

          {stipend?.status && (
            <ListItem sx={{ padding: "0px" }}>
              <Alert
                sx={{
                  width: "100%",
                  ".MuiAlert-icon": {
                    color: actionColor,
                  },
                }}
                severity={statusSeverity(stipend.status)}
              >
                <AlertTitle>
                  <Typography
                    data-test-id="status"
                    variant="subtitle2"
                    color="text.secondary"
                  >
                    {t("payment_status")}
                  </Typography>
                </AlertTitle>
                <Typography
                  data-test-id="status-value"
                  variant="body2"
                  color="text.primary"
                >
                  {getStatusText(
                    stipend.status,
                    stipend.action_on &&
                      convertDateToFormattedString(stipend.action_on)
                  )}
                </Typography>
                {stipend.payment_account?.target_account_type ===
                  PaymentType.BankAccount && (
                  <Link
                    textAlign={"left"}
                    component="button"
                    variant="body2"
                    onClick={handleClickOpen}
                  >
                    {t("bank_processing_title")}
                  </Link>
                )}
              </Alert>
            </ListItem>
          )}
        </List>
      </Card>
      <BankPaymentProcessingDialog open={open} onClose={handleClose} />
    </Grid>
  );
};
export default PaymentDetails;
