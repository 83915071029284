import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentAccountDetails,
  PaymentMethodsResponse,
} from "../../types/paymentTypes";
import { RootState } from "../store";

export const RESET_STATE = "RESET_STATE";

interface PaymentMethodsState {
  data: PaymentMethodsResponse | null;
  formStep: number;
}

const initialState: PaymentMethodsState = {
  data: null,
  formStep: 1,
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    setPaymentMethodsSuccess: (
      state,
      action: PayloadAction<PaymentMethodsResponse>
    ) => {
      state.data = action.payload;
    },
    setLatestPaymentMethod: (
      state,
      action: PayloadAction<PaymentAccountDetails>
    ) => {
      if (state.data !== null)
        state.data.latest_payment_account = action.payload;
    },
    setFormStep: (state, action: PayloadAction<number>) => {
      state.formStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

// Selector to get the user's country from the paymentMethods slice
const selectUserCountry = (state: RootState) =>
  state.paymentMethods.data?.country;

// Selector to get the currency based on the user's country
export const selectCurrency = createSelector(
  [selectUserCountry],
  (country): string => {
    switch (country) {
      case "US":
        return "USD";
      case "GB":
        return "GBP";
      default:
        return "USD";
    }
  }
);

const symbols: { [key: string]: string } = {
  USD: "$",
  GBP: "£",
  EUR: "€",
};

// Define a selector to get the currency symbol based on the currency code
export const selectCurrencySymbol = createSelector(
  [selectCurrency],
  (currencyCode): string => {
    return symbols[currencyCode];
  }
);

export const { setPaymentMethodsSuccess, setLatestPaymentMethod, setFormStep } =
  paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
