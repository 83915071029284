import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, IconButton, Typography, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useTranslation } from "react-i18next";

const CreateVccCard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Card
        data-test-id="create-vcc-container"
        variant="outlined"
        onClick={() => navigate("/vcc-preview")}
        sx={{ width: "100%", border: "1px solid grey", cursor: "pointer" }}
        className="mt-2"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={1}
          alignItems="center"
        >
          <Box display="flex" p={1} alignItems="center">
            <AddCardIcon
              data-test-id="create-vcc-icon"
              fontSize="large"
              sx={{ color: "#808080" }}
            />
            <Box display="flex" flexDirection="column" marginLeft={2}>
              <Typography
                data-test-id="create-vcc-title"
                variant="subtitle2"
                sx={{ textAlign: "left", fontWeight: "600" }}
              >
                {t("noneOfAbove")}
              </Typography>
              <Typography
                data-test-id="create-vcc-description"
                variant="body2"
                color="text.secondary"
              >
                {t("vcc_create")}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pl={5}
            gap={1}
          >
            <IconButton>
              <ArrowForwardIosIcon data-test-id="vcc-arrow" fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CreateVccCard;
