import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { api } from "../api/api";
import { loginService } from "../api/AuthService";
import { CircularProgress, Typography, Box } from "@mui/material";
import StorageManager from "../services/storage";
import { Token } from "../types/common";
import { reset } from "../redux/store";
import { didLanguageFallback } from "../i18n";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/slices/toastSlice";
import { t } from "i18next";
import { setTokens } from "../redux/slices/authSlice";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);

  const language = searchParams.get("lng") ?? undefined;
  const token = searchParams.get("token")?.replace(/ /g, "+");
  const resetState = async () => {
    await reset();
  };

  function checkLanguageFallback() {
    let usedFallback = didLanguageFallback(language ?? "en-US");
    if (usedFallback) {
      dispatch(showToast(t("error_notSupportedLanguage")));
    }
  }

  useEffect(() => {
    if (token) {
      resetState();
      StorageManager.clear();
      setLoading(true);
      api<Token>(loginService(token as string))
        .then((response) => {
          dispatch(
            setTokens({
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
            })
          );
          checkLanguageFallback();
          navigate("/payments", { replace: true });
        })
        .catch(() => {
          navigate("/payments", { replace: true });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/payments", { replace: true });
    }
  }, []);

  return (
    <Box
      display="flex"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      {isLoading && <CircularProgress data-test-id="loader" />}
    </Box>
  );
};

export default LoginPage;
