import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Grid,
  Box,
  Stack,
  Button,
} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { listItemStyle } from "../common/constants";
import PaymentService from "../api/paymentService";
import { setLatestPaymentMethod } from "../redux/slices/paymentMethodsSlice";
import { useNavigate } from "react-router-dom";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { LoadingButton } from "@mui/lab";
import { formatNumber, parsePhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorType, getErrorDetails } from "../common/CustomErrorBoundary";
import { showError } from "../redux/slices/toastSlice";
import StorageManager from "../services/storage";
import {
  removeSpacesAndHyphens,
  stripTextAfterHyphen,
} from "../common/helpers";
import { PaymentAccountDetails } from "../types/paymentTypes";
import { api } from "../api/api";

const VccCardReview: React.FC = () => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();

  const data = useSelector(
    (state: RootState) => state.vcc.vccPaymentData
  ) as any;

  const { properties = {}, states } = useSelector((state: RootState) => {
    const paymentMethod =
      state.paymentMethods.data?.available_payment_methods.find(
        (payment) => payment.payment_type === "VIRTUAL_CARD"
      );

    const properties = paymentMethod?.properties.find(
      (property) => property.property_name === "address"
    )?.properties;

    const states = properties?.state.options ?? [];

    return { properties, states };
  });

  function getStateName(key: string): any {
    return states.find((state: any) => state.key === key)?.value;
  }

  const getTitleByKey = (key: string): string => {
    const prop = properties[key];
    return prop ? prop.title : key;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  if (!data) {
    return null;
  }

  const { full_name, phone, address, email } = data;

  const handlePayment = async () => {
    try {
      setLoading(true);
      // Processing the zip code
      const processedZip = removeSpacesAndHyphens(
        stripTextAfterHyphen(data.address.zip)
      );

      const cleanedPhone = data.phone.replace(/\s+/g, "");
      const phoneObject = parsePhoneNumber(cleanedPhone);
      const nationalNumber = phoneObject.nationalNumber;
      const countryCallingCode = phoneObject.countryCallingCode;

      const trimmedData = {
        ...data,
        phone: nationalNumber,
        phone_country_code: countryCallingCode,
        full_name: data.full_name.trim(),
        email: data.email.trim(),
        address: {
          ...data.address,
          zip: processedZip,
        },
      };
      const response = await api<PaymentAccountDetails>(
        PaymentService.createPayment(trimmedData, data.endpoint)
      );
      dispatch(setLatestPaymentMethod(response.data));
      StorageManager.removeFormData();
      navigate("/vcc-create");
    } catch (error: any) {
      const errorDetails = getErrorDetails(error, t);
      if (errorDetails && errorDetails.type !== ErrorType.TERMS_UPDATED) {
        dispatch(
          showError({
            title: errorDetails.props.title,
            message: errorDetails.props.message,
          })
        );
      } else {
        showBoundary(error);
      }
    } finally {
      setLoading(false);
    }
  };
  const addressString = address
    ? Object.entries(address)
        .filter(([key]) => key !== "country_code")
        .map(([key, value]) =>
          key === "state" ? getStateName(value as string) : value
        )
        .join(", ")
    : t("notProvided");

  return (
    <Grid container direction="column">
      <List>
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            data-test-id="name-label"
            secondary={t("fullName")}
            primary={full_name || t("notProvided")}
          />
        </ListItem>

        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText
            data-test-id="email-label"
            secondary={t("email")}
            primary={email || t("notProvided")}
          />
        </ListItem>

        <ListItem sx={listItemStyle}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
            </Grid>
            <Grid item xs>
              <Collapse
                data-test-id="address-label"
                in={true}
                timeout="auto"
                unmountOnExit
              >
                {addressString.split(", ").map((line, index) => (
                  <Box key={index}>
                    <ListItemText
                      data-test-id={"address-" + line}
                      primary={line}
                    />
                  </Box>
                ))}
                <ListItemText secondary={t("billingAddress")} />
              </Collapse>
            </Grid>
          </Grid>
        </ListItem>
        {phone && (
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText
              data-test-id="phone-label"
              secondary={t("phone")}
              primary={formatNumber(phone, "US", "INTERNATIONAL")}
            />
          </ListItem>
        )}
      </List>
      <Grid item>
        <Stack spacing={2} justifyContent="center">
          <Button
            data-test-id="tc-edit-button"
            onClick={() => navigate(-2)}
            size="large"
            variant="text"
            sx={{ color: "gray" }}
            disabled={isLoading}
          >
            <span>{t("vcc_edit")}</span>
          </Button>
          <LoadingButton
            data-test-id="create-button"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handlePayment}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<AddCardIcon />}
          >
            <span>{t("vcc_createMyCard")}</span>
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default VccCardReview;
