import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PaymentsPage from "./pages/PaymentsPage";
import PaymentMethods from "./pages/PaymentMethodsPage";
import TermsAndConditions from "./pages/TermsAndConditionsPage";
import PaymentDetails from "./pages/PaymentDetailsPage";
import MainContainer from "./containers/MainContainer";
import VccCardPreview from "./pages/VccCardPreview";
import VccVisualise from "./pages/VccVisualisePage";
import VccCardReview from "./pages/VccCardReviewPage";
import VccCardCreated from "./pages/VccCardCreatedPage";
import VccCardSetup from "./pages/VccCardSetup";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound";
import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { hideToast, showGlobalMessage } from "./redux/slices/toastSlice";
import { RootState } from "./redux/store";
import Support from "./pages/SupportPage";
import { configService } from "./services/configService";
import CloseIcon from "@mui/icons-material/Close";
import StorageManager from "./services/storage";
import { getLocalizedMessage } from "./common/helpers";
import PaymentInputForm from "./pages/PaymentInputForm";

function App() {
  const dispatch = useDispatch();
  const toast = useSelector((state: RootState) => state.toast);
  const showSupportRoute = configService.config.showSupport;
  const message = getLocalizedMessage(process.env.REACT_APP_GLOBAL_MESSAGE);

  useEffect(() => {
    if (message && !StorageManager.didDisplayGlobalMessage()) {
      dispatch(showGlobalMessage(message));
    }
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideToast());
    StorageManager.setDisplayedGlobalMessage();
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Router>
      <MainContainer>
        <Routes>
          <Route path="/" element={<Navigate replace to="/payments" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/payment/:transaction_id" element={<PaymentDetails />} />

          <Route path="/payment-methods" element={<PaymentMethods />} />
          <Route
            path="/payment-methods/:paymentType"
            element={<PaymentInputForm />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/vcc-preview" element={<VccCardPreview />} />
          <Route path="/vcc-setup" element={<VccCardSetup />} />
          <Route path="/vcc-card" element={<VccVisualise />} />
          <Route path="/vcc-review" element={<VccCardReview />} />
          <Route path="/vcc-create" element={<VccCardCreated />} />
          <Route path="/vcc-card" element={<VccVisualise />} />
          <Route path="/not-found" element={<NotFound />} />
          {showSupportRoute && <Route path="/support" element={<Support />} />}

          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </MainContainer>
      {toast.error && (
        <Snackbar
          open={toast.open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            <AlertTitle>
              <Typography
                data-test-id="error-title"
                variant="subtitle2"
                sx={{ fontWeight: "bold" }}
              >
                {toast.error.title}
              </Typography>
            </AlertTitle>

            {toast.error.message}
          </Alert>
        </Snackbar>
      )}
      {toast.message && (
        <Snackbar
          open={toast.open}
          autoHideDuration={toast.autoHide ? 5000 : null}
          onClose={handleClose}
          message={toast.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          action={action}
        />
      )}
    </Router>
  );
}

export default App;
