import {
  BankAccountData,
  ChequePaymentData,
  DebitCardData,
  VccPaymentData,
} from "../types/paymentTypes";
import { AxiosRequestConfig } from "axios";

export const getPaymentMethods = (): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods",
    method: "GET",
  };
};

export const createDebitCard = (data: DebitCardData): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/debit-card",
    method: "POST",
    data: data,
  };
};

export const createBankAccount = (
  data: BankAccountData
): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/bank-account",
    method: "POST",
    data: data,
  };
};

export const createCheque = (data: ChequePaymentData): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/cheque",
    method: "POST",
    data: data,
  };
};

export const createVirtualCard = (data: VccPaymentData): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/vc",
    method: "POST",
    data: data,
  };
};

export const getVirtualCard = (): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/vc",
    method: "GET",
  };
};
