import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import {
  CreditCardOff,
  Timer,
  SyncDisabled,
  Home,
  LocalPhone,
  Email,
  Security,
  CheckCircle,
} from "@mui/icons-material";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import IconList from "./IconList";
import { useTranslation } from "react-i18next";

const VccCardSetup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderElements = [
    { icon: <CreditCardOff />, text: t("vcc_setup_card_digital") },
    { icon: <Timer />, text: t("vcc_setup_card_validity") },
    { icon: <SyncDisabled />, text: t("vcc_setup_no_transfer") },
    { icon: <Home />, text: t("vcc_setup_billing_address") },
    { icon: <LocalPhone />, text: t("vcc_setup_phone_number") },
    { icon: <Email />, text: t("vcc_setup_email") },
    { icon: <Security />, text: t("vcc_setup_secure_info") },
  ];

  const vccPaymentMethod = useSelector(
    (state: RootState) => state.paymentMethods.data
  )?.available_payment_methods.find((item) => {
    return item.payment_type === "VIRTUAL_CARD";
  });

  const handleContinueButton = async () => {
    if (vccPaymentMethod) {
      navigate(`/payment-methods/${vccPaymentMethod.payment_type}`, {
        state: { selectedMethod: vccPaymentMethod },
      });
    }
  };

  return (
    <Box>
      <Typography
        data-test-id="vcc-setup-title"
        sx={{
          fontSize: "14px",
          color: "text.secondary",
          pb: "20px",
          pt: "4px",
          fontWeight: "500",
        }}
      >
        {t("vcc_setup_title")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          pb: "20px",
        }}
      >
        <IconList items={renderElements} />
      </Box>
      <Button
        data-test-id="vcc-setup-button"
        fullWidth
        variant="contained"
        size="large"
        startIcon={<CheckCircle />}
        onClick={handleContinueButton}
      >
        {t("okGotIt")}
      </Button>
    </Box>
  );
};

export default VccCardSetup;
