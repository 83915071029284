import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

interface SupportedLanguages {
  [language: string]: {
    regions: string[];
    default: string;
  };
}

const supportedLanguages: SupportedLanguages = {
  en: {
    regions: ["en-US"],
    default: "en-US",
  },
  zh: {
    regions: ["zh"],
    default: "zh",
  },
  es: {
    regions: ["es"],
    default: "es",
  },
};

interface LocaleResult {
  locale: string;
  usedFallback: boolean;
}

export function didLanguageFallback(languageIdentifier: string): boolean {
  let langInfo = getLocale(languageIdentifier, supportedLanguages);
  return langInfo.usedFallback;
}

function getLocale(
  languageIdentifier: string,
  supportedLanguages: SupportedLanguages,
  countryCode?: string
): LocaleResult {
  const parts = languageIdentifier.split("-");
  const language = parts[0].toLowerCase();
  const region = parts.length > 1 ? parts[1].toUpperCase() : undefined;

  // First priority is given to the provided countryCode, if valid
  const regionalIdentifier = countryCode
    ? `${language}-${countryCode.toUpperCase()}`
    : region
    ? `${language}-${region}`
    : language;

  // Check if the language is supported
  if (supportedLanguages[language]) {
    // Check for exact regional match or a match with countryCode
    if (
      regionalIdentifier &&
      supportedLanguages[language].regions.includes(regionalIdentifier)
    ) {
      return { locale: regionalIdentifier, usedFallback: false };
    }

    // Fallback to the default for the language
    return {
      locale: supportedLanguages[language].default,
      usedFallback: false,
    };
  }

  // Fallback to 'en-US' if language not supported
  return { locale: "en-US", usedFallback: true };
}

function customDetector(lng: string) {
  const determinedLocale = getLocale(lng, supportedLanguages);
  return determinedLocale.locale;
}

const i18nInstance = i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en-US",
    supportedLngs: Object.keys(supportedLanguages).flatMap(
      (lang) => supportedLanguages[lang].regions
    ),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "localStorage"],
      caches: ["localStorage"],
      lookupQuerystring: "lng",
      convertDetectedLanguage: customDetector,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: false,
    },
  });

export default i18nInstance;
