import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../api/api";
import {
  getPaymentMethods,
  getVirtualCard,
} from "../api/paymentMethodsService";
import { paymentsService } from "../api/stipendsServices";
import no_payments from "../assets/no_payments.svg";
import {
  convertDateToFormattedString,
  formatNumber,
  getLocalizedMessage,
} from "../common/helpers";
import LatestPayment from "../components/Payment/LatestPayment";
import StipendCard from "../components/Stipend/StipendCard";
import {
  getStatusText,
  statusSeverity,
} from "../components/Stipend/StipendCommon";
import {
  selectCurrencySymbol,
  setPaymentMethodsSuccess,
} from "../redux/slices/paymentMethodsSlice";
import { setData } from "../redux/slices/stipendSlice";
import { setVccData } from "../redux/slices/vccSlice";
import { RootState } from "../redux/store";
import theme from "../theme";
import { VccCardType } from "../types/common";
import {
  AvailablePaymentMethod,
  PaymentMethodProp,
  PaymentMethodsResponse,
  VirtualCardResponse,
} from "../types/paymentTypes";
import { Payment } from "../types/stipendTypes";

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation();
  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  const {
    data: paymentsData,
    loading: loadingPayments,
    error: paymentsError,
  } = useAPI<Payment[]>(paymentsService(), []);

  const {
    data: vccData,
    loading: isVCCLoading,
    error: vccError,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard());

  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    error: paymentMethodsError,
  } = useAPI<PaymentMethodsResponse | null>(getPaymentMethods());

  function updatePropertyNameForChildren(
    obj: PaymentMethodProp
  ): PaymentMethodProp {
    if (!obj.properties) return obj;

    const updatedProperties = Object.entries(obj.properties).reduce(
      (acc, [key, value]) => {
        // Create a shallow copy of value
        const valueCopy = { ...value };

        // Setting the key as property_name for the copied object
        valueCopy.property_name = key;

        // Recursive call to handle further nesting
        acc[key] = updatePropertyNameForChildren(valueCopy);

        return acc;
      },
      {} as Record<string, PaymentMethodProp>
    );

    return { ...obj, properties: updatedProperties };
  }

  const paymentsMessage = getLocalizedMessage(
    process.env.REACT_APP_PAYMENTS_MESSAGE
  );

  function updateAvailablePaymentMethods(
    methods: AvailablePaymentMethod[]
  ): AvailablePaymentMethod[] {
    // return methods.map((method) => {
    //   const updatedProperties = method.properties.map((prop) =>
    //     updatePropertyNameForChildren(prop)
    //   );
    //   return { ...method, properties: updatedProperties };
    // });
    const updatedData = methods;
    methods.forEach((paymentMethod: any) => {
      paymentMethod.properties.forEach((parentProperty: any) => {
        if (parentProperty.type == "object") {
          parentProperty.nestedProps = [];

          Object.keys(parentProperty.properties || {}).map(
            (nestedPropKey: string) => {
              parentProperty.nestedProps?.push({
                ...parentProperty?.properties![nestedPropKey],
                property_name: nestedPropKey,
              });
            }
          );
        }
      });
    });

    return updatedData;
  }

  useEffect(() => {
    if (!paymentMethodsData) {
      return;
    }
    // Update property_name for all nested properties in available_payment_methods
    const updatedPaymentMethods = updateAvailablePaymentMethods(
      paymentMethodsData.available_payment_methods
    );

    const updatedPaymentMethodsData: PaymentMethodsResponse = {
      ...paymentMethodsData,
      available_payment_methods: updatedPaymentMethods,
    };

    dispatch(setPaymentMethodsSuccess(updatedPaymentMethodsData));
    if (paymentMethodsData?.latest_payment_account === null) {
      navigate("/payment-methods?isFirstTime=true", { replace: true });
    }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (!paymentsError) {
      return;
    }
    showBoundary(paymentsError);
  }, [paymentsError]);

  useEffect(() => {
    if (!paymentMethodsError) {
      return;
    }

    showBoundary(paymentMethodsError);
  }, [paymentMethodsError]);

  useEffect(() => {
    if (!vccError) {
      return;
    }

    // if 404, the user doesn't have a vcc
    if (vccError.response?.status == 404) {
      return;
    }

    showBoundary(vccError);
  }, [vccError]);

  useEffect(() => {
    if (vccData && vccData[0]) {
      const transformed: VccCardType = {
        target_account_id: vccData[0].id,
        card_number: vccData[0].card_number,
        balance: vccData[0].balance,
      };
      dispatch(setVccData(transformed));
    }
  }, [vccData, dispatch]);

  useEffect(() => {
    if (paymentsData) {
      dispatch(setData(paymentsData));
    }
  }, [paymentsData]);

  if (loadingPayments || paymentMethodsLoading)
    return (
      <Paper
        data-test-id="payments-skeleton"
        style={{ padding: "30px 16px" }}
        elevation={0}
      >
        <Skeleton
          variant="rounded"
          height={96}
          style={{ marginBottom: "10px" }}
        />
        <Skeleton
          variant="rounded"
          height={96}
          style={{ marginBottom: "10px" }}
        />
        <Skeleton
          variant="rounded"
          height={96}
          style={{ marginBottom: "10px" }}
        />
      </Paper>
    );

  return (
    <Paper elevation={0}>
      <div>
        {paymentsMessage && <Alert severity="warning">{paymentsMessage}</Alert>}
        <List>
          {paymentMethodsData?.has_failed_payments && (
            <StipendCard
              icon={<ErrorOutlineIcon />}
              title={t("failedPaymentWarning_title")}
              description={t("failedPaymentWarning_description")}
              color="white"
              backgroundColor="#D32F2F"
              linkTo="/payment-methods?failedPayment=true"
            />
          )}
          {vccData && vccData.length > 0 && (
            <>
              <Typography
                color="text.secondary"
                variant="subtitle2"
                fontWeight="600"
              >
                {t("vcc_yourCard")}
              </Typography>
              <Box
                sx={{
                  padding: 0,
                  mt: 1,
                  mb: "10px",
                  cursor: "pointer",
                  borderRadius: 1,
                  border: "1px solid #0000001F",
                  "& *": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/vcc-card")}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  p={1}
                  alignItems="center"
                >
                  <ListItem dense disableGutters sx={{ padding: 0 }}>
                    <CreditCardRoundedIcon
                      data-test-id="vcc-icon"
                      sx={{ fontSize: 30 }}
                      style={{ color: "gray" }}
                    />
                    <ListItemText
                      sx={{ cursor: "default", textAlign: "left", ml: 1 }}
                      data-test-id="vcc-balance"
                      primary={
                        <Typography variant="subtitle2" fontWeight="600">
                          {t("vcc_availableBalance")}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          data-test-id="vcc-balance-title"
                          variant="body2"
                          color="text.secondary"
                        >
                          {vccData[0]
                            ? `${currencySymbol} ${formatNumber(
                                vccData[0].balance
                              )}`
                            : `${currencySymbol} 0.00`}
                        </Typography>
                      }
                    />
                    <KeyboardArrowRightIcon color="action" />
                  </ListItem>
                </Box>
              </Box>
            </>
          )}

          {
            <Typography
              sx={{
                mt: 2,
                mb: 1,
              }}
              variant="subtitle2"
              color="text.secondary"
              fontWeight="600"
            >
              {t("payments")}
            </Typography>
          }
          {paymentsData?.length === 0 ? (
            <Stack
              spacing={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert
                data-test-id="payments-empty-text"
                severity="info"
                style={{ width: "100%", color: theme.palette.primary.main }}
              >
                {t("payments_empty")}
              </Alert>
              <img
                data-test-id="payments-empty-icon"
                src={no_payments}
                alt="No Payments Icon"
                style={{ width: 200, height: 200 }}
              />
            </Stack>
          ) : (
            paymentsData?.map((stipend, index) => {
              return (
                <StipendCard
                  key={stipend.transaction_id}
                  subtitle={stipend.payment_reason}
                  title={`${currencySymbol} ${formatNumber(stipend.amount)}`}
                  description={getStatusText(
                    stipend.status,
                    stipend.action_on &&
                      convertDateToFormattedString(stipend.action_on)
                  )}
                  severity={statusSeverity(stipend.status)}
                  linkTo={`/payment/${stipend.transaction_id}`}
                  showRightChevron={false}
                />
              );
            })
          )}
        </List>
        {paymentMethodsData?.latest_payment_account && (
          <LatestPayment
            paymentAccount={paymentMethodsData.latest_payment_account}
            failedPayment={paymentMethodsData.has_failed_payments}
          />
        )}
      </div>
    </Paper>
  );
};

export default PaymentsPage;
