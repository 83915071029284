import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { setupInterceptors } from "./Interceptors";
import { RootState } from "../redux/store";

export const api = axios.create({
  baseURL: process.env.REACT_APP_NMIBLE_SERVER,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    version: "v0.1",
  },
});

export const evervaultAPI = axios.create({
  baseURL: process.env.REACT_APP_EVERVAULT_SERVER,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    version: "v0.1",
  },
});

export const loqateAPI = axios.create({
  baseURL: process.env.REACT_APP_LOQATE_SERVER,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

setupInterceptors(api);

export const useAPI = <T>(service: AxiosRequestConfig, deps: any[] = []) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    setLoading(true);

    api<T>(service)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error as AxiosError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, deps);

  return { data, loading, error };
};
