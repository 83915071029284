import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Alert, Box, Divider, Link, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDecrypt from "../../common/useDecrypt";
import { PaymentAccountDetails, PaymentType } from "../../types/paymentTypes";
import BankPaymentProcessingDialog from "./BankPaymentProcessingDialog";

export const paymentMethodIcon = (paymentMethod: string) => {
  switch (paymentMethod) {
    case "BANK_ACCOUNT":
      return <AccountBalanceIcon color="action" />;
    case "CHEQUE":
      return <AttachEmailIcon color="action" />;
    default:
      return <CreditCardIcon color="action" />;
  }
};

interface LatestPaymentProps {
  paymentAccount: PaymentAccountDetails;
  showChangeButton?: boolean;
  failedPayment?: boolean;
  useTableStyle?: boolean;
}

const LatestPayment: React.FC<LatestPaymentProps> = ({
  paymentAccount,
  showChangeButton = true,
  failedPayment = false,
  useTableStyle = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onProceed = () => {
    let url = "/payment-methods";

    if (failedPayment) {
      url += "?failedPayment=true";
    }

    navigate(url);
  };

  const paymentType = paymentAccount.target_account_type.toLowerCase();
  const showLatestFourDigits =
    paymentType !== "check" && paymentType !== "cheque";
  const shouldDecrypt = paymentType !== "bank_account";

  const { decryptedId, decryptionLoading } = useDecrypt(
    paymentAccount.target_account_identifier,
    [],
    showLatestFourDigits,
    shouldDecrypt
  );

  return (
    <Box
      sx={!useTableStyle ? { border: "1px solid #0000001F", my: "1.3rem" } : {}}
    >
      <Alert
        icon={paymentMethodIcon(paymentAccount.target_account_type)}
        data-test-id="payment-account"
        sx={{
          textAlign: "left",
          background: "inherit",
          ...(useTableStyle && {
            width: "100%",
            border: "none",
            mt: "0px",
            mb: "0px",
          }),
        }}
      >
        <Typography
          data-test-id="payment-account-title"
          variant="subtitle2"
          color="text.secondary"
        >
          {t("paymentMethod_title")}
        </Typography>
        <Typography variant="body2" data-test-id="payment-account-description">
          {decryptionLoading ? (
            <Skeleton variant="text" width={200}></Skeleton>
          ) : (
            t("paymentMethod_accountInfo", {
              accountType: paymentAccount.target_account_title,
              id: decryptedId,
            })
          )}
        </Typography>

        {showChangeButton && (
          <Typography>
            <Link
              href=""
              onClick={onProceed}
              underline="always"
              data-test-id="update-payment-method"
            >
              {t("paymentMethod_update")}
            </Link>
          </Typography>
        )}
      </Alert>
      {!useTableStyle &&
        paymentAccount.target_account_type === PaymentType.BankAccount && (
          <Box maxWidth={"100%"}>
            <Divider sx={{ mb: 1.3 }}></Divider>
            <Typography sx={{ pl: 2, pb: 1.3 }}>
              <Link variant="body2" onClick={handleClickOpen}>
                {t("bank_processing_title")}
              </Link>
            </Typography>
          </Box>
        )}
      <BankPaymentProcessingDialog open={open} onClose={handleClose} />
    </Box>
  );
};

export default LatestPayment;
