import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Grid,
  Box,
  Typography,
  Alert,
  Skeleton,
} from "@mui/material";
import { WhiteSecurityIcon } from "../common/constants";
import { useTranslation } from "react-i18next";
import {
  selectCurrencySymbol,
  setLatestPaymentMethod,
} from "../redux/slices/paymentMethodsSlice";
import CreateVccCard from "../components/VccCard/CreateVccCard";
import { useAPI } from "../api/api";
import paymentService from "../api/paymentService";
import {
  AvailablePaymentMethod,
  PaymentMethodsResponse,
  VirtualCardResponse,
} from "../types/paymentTypes";
import { setVccData } from "../redux/slices/vccSlice";
import { VccCardType } from "../types/common";
import useDecrypt from "../common/useDecrypt";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { getVirtualCard } from "../api/paymentMethodsService";
import { showError, showToast } from "../redux/slices/toastSlice";
import { Payment } from "../types/stipendTypes";
import { paymentsService } from "../api/stipendsServices";
import { useErrorBoundary } from "react-error-boundary";
import { AxiosError } from "axios";
import { ErrorType, getErrorDetails } from "../common/CustomErrorBoundary";
import StorageManager from "../services/storage";

const PaymentMethods: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const failedPayment = searchParams.get("failedPayment") === "true";

  const paymentMethodsData = useSelector(
    (state: RootState) => state.paymentMethods.data
  ) as PaymentMethodsResponse;

  const isVCCPaymentMethodAvailable =
    paymentMethodsData.available_payment_methods.some(
      (item) => item.payment_type === "VIRTUAL_CARD"
    ); // contains item where
  const {
    data: vccData,
    loading: isVCCLoading,
    error: vccError,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard());

  const {
    data: stipends,
    loading: loadingStipends,
    error: stipendsError,
  } = useAPI<Payment[]>(paymentsService(), []);

  const latestPaymentMethod = useSelector(
    (state: RootState) => state.paymentMethods.data?.latest_payment_account
  ) as any;
  const vcc = useSelector<RootState, any | null>((state) => state.vcc.vccData);
  const hasVcc = vcc !== null;

  const { decryptedId: decryptedVccValue, decryptionLoading } = useDecrypt(
    vcc?.target_account_identifier,
    [vcc]
  );
  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  const sumAmountFromStipends = React.useMemo(() => {
    return stipends?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.amount;
    }, 0);
  }, [stipends?.length]);

  const [isLoading, setLoading] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<string>("");
  const [selectedMethod, setSelectedMethod] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (!vccError) {
      return;
    }
    // if 404, the user doesn't have a vcc
    if (vccError.response?.status == 404) {
      return;
    }
    showBoundary(vccError);
  }, [vccError]);

  useEffect(() => {
    if (vccData && vccData[0]) {
      const transformed: VccCardType = {
        target_account_id: vccData[0].id,
        card_number: vccData[0].card_number,
        balance: vccData[0].balance,
      };
      dispatch(setVccData(transformed));
    }
  }, [vccData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentType((event.target as HTMLInputElement).value);
    const method = paymentMethodsData?.available_payment_methods.find(
      (method: any) =>
        method.payment_type === (event.target as HTMLInputElement).value
    );
    setSelectedMethod(method);
  };

  const handleContinueClick = async () => {
    if (selectedMethod.payment_type === "VIRTUAL_CARD" && hasVcc) {
      if (latestPaymentMethod.target_account_type !== "VIRTUAL_CARD") {
        const { balance, ...vccReqWithoutBalance } = vcc;
        dispatch(setLatestPaymentMethod(vccReqWithoutBalance));
      }
      if (vcc !== null && vcc?.target_account_id) {
        setLoading(true);
        try {
          const vccPaymentCreationData =
            await paymentService.createVccPayment();
          dispatch(setLatestPaymentMethod(vccPaymentCreationData));
          dispatch(showToast(t("paymentMethod_updated")));
          setLoading(false);
          navigate("/payments");
        } catch (err) {
          const errorDetails = getErrorDetails(err as AxiosError, t);
          if (errorDetails && errorDetails.type !== ErrorType.TERMS_UPDATED) {
            dispatch(
              showError({
                title: errorDetails.props.title,
                message: errorDetails.props.message,
              })
            );
          } else {
            showBoundary(err);
          }
          setLoading(false);
        }
        return;
      }
    }
    if (selectedMethod) {
      StorageManager.removeFormData();
      navigate(`/payment-methods/${selectedMethod.payment_type}`);
    }
  };

  return (
    <>
      {!latestPaymentMethod &&
        (loadingStipends ? (
          <Skeleton
            variant="rounded"
            height={48}
            style={{ marginBottom: 10 }}
          />
        ) : (
          <Alert
            severity="info"
            sx={{
              backgroundColor: "#0588D1",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {sumAmountFromStipends === 0
              ? t("paymentMethod_choose")
              : t("paymentMethod_amountWaiting", {
                  currency: currencySymbol,
                  amount: sumAmountFromStipends?.toFixed(2),
                })}
          </Alert>
        ))}
      {failedPayment && (
        <Alert
          severity="info"
          sx={{
            backgroundColor: "#0588D1",
            marginBottom: "10px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {t("paymentMethod_retry")}
        </Alert>
      )}
      {isVCCLoading ? (
        <Skeleton
          variant="rounded"
          height={142}
          style={{
            marginBottom: "16px",
          }}
        />
      ) : (
        <Grid
          container
          direction="column"
          borderRadius="4px"
          border="1px solid grey"
          p={2}
          mb={2}
        >
          <Grid textAlign="left" item>
            <FormControl component="fieldset">
              <RadioGroup
                data-test-id="payment-type"
                name="payment-type"
                value={selectedPaymentType}
                onChange={handleChange}
              >
                {paymentMethodsData?.available_payment_methods
                  .filter((method: any) => {
                    if (hasVcc) {
                      return method.priority !== 5;
                    } else {
                      return (
                        method.priority !== 5 &&
                        method.payment_type !== "VIRTUAL_CARD"
                      );
                    }
                  })
                  .map((method: any, index: number) => (
                    <FormControlLabel
                      sx={{ fontSize: "0.875rem" }}
                      key={index}
                      value={method.payment_type}
                      control={<Radio />}
                      label={
                        <Typography
                          data-test-id="payment-method-title"
                          fontSize="0.875rem"
                          style={{ display: "flex" }}
                        >
                          {method.payment_type === "VIRTUAL_CARD" && hasVcc ? (
                            <>
                              {method.title + " "}
                              {decryptedVccValue === null &&
                              vcc?.target_account_id ? ( // Check for loading here
                                <span>
                                  <Skeleton
                                    variant="rounded"
                                    height={20}
                                    width={45}
                                    style={{ marginLeft: "10px" }}
                                  />
                                </span>
                              ) : (
                                decryptedVccValue
                              )}
                            </>
                          ) : (
                            method.title
                          )}
                        </Typography>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item width="100%">
            <Box mt={3}>
              <LoadingButton
                data-test-id="payment-methods-button"
                fullWidth
                size="large"
                variant="contained"
                disabled={!selectedMethod}
                onClick={handleContinueClick}
                loading={isLoading}
                loadingPosition="start"
                startIcon={<WhiteSecurityIcon />}
              >
                <span> {t("continue")}</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
      {isVCCPaymentMethodAvailable && !hasVcc && <CreateVccCard />}
    </>
  );
};

export default PaymentMethods;
